import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import _ from 'lodash'

class Animations {
    constructor(props) {
        this.props = props

        this.init()
    }

    init() {
        this.bannerImage()
        this.bannerSlider()

        setTimeout(() => {
            this.animation()
            this.group()
        }, 500)
    }

    bannerImage() {
        const bannerImage = document.querySelector('.banner-image')

        if (bannerImage) {
            gsap.to(".banner", {
                scrollTrigger: {
                    trigger: ".banner-image",
                    scrub: 0.5,
                    start: "top top",
                    end: "bottom 100px",
                    ease: "power2",
                    toggleClass: "active",
                    onRefresh: () => {
                        bannerImage.classList.add('show')
                        setTimeout(() => {
                            bannerImage.classList.add('show-content')
                        }, 500)
                    },
                    onUpdate: ({progress, direction, isActive}) => {
                        if (progress > 0) {
                            _.throttle(() => {
                                bannerImage.classList.remove('show-content')
                            }, 300)()
                        } else {
                            _.debounce(() => {
                                bannerImage.classList.add('show-content')
                            }, 500)()
                        }
                    },
                    onEnter: () => bannerImage.classList.remove('leave'),
                    onEnterBack: () => bannerImage.classList.remove('leave'),
                    onLeave: () => bannerImage.classList.add('leave'),
                },
                yPercent: 20,
                scale: 1.2
            })
        }
    }

    bannerSlider() {
        const bannerSlider = document.querySelector('.banner-slider')

        if (bannerSlider) {
            gsap.to(".banner-slider .slide picture", {
                scrollTrigger: {
                    trigger: ".banner-slider",
                    scrub: 0.5,
                    start: "top top",
                    end: "bottom 100px",
                    ease: "power2",
                    toggleClass: "active",
                    onRefresh: () => {
                        bannerSlider.classList.add('show')
                    },
                },
                y: "10%",
                scale: 1.2
            })
        }
    }

    animation() {
        const animate = document.querySelectorAll('.animate')
        const animateGroup = document.querySelectorAll('.animate-group')

        if (animate.length > 0) {
            animate.forEach(elem => {
                ScrollTrigger.create({
                    trigger: elem,
                    toggleClass: 'show',
                    start: "top center",
                    end: "+=0",
                    toggleActions: "play none none none",
                    once: true
                })
            })
        }

        if (animateGroup.length > 0) {

            animateGroup.forEach(elem => {

                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: elem,
                        start: "top center",
                        once: true
                    }
                })

                gsap.defaults({
                    ease: 'ease-in-out',
                    duration: .5
                })

                const children = elem.querySelectorAll(':scope > *')

                const firstChild = children[0];

                let groupChildren;

                if([...firstChild.classList].includes('wp-block-group__inner-container')) {
                    groupChildren = firstChild.querySelectorAll(':scope > *')
                } else {
                    groupChildren = children
                }

                groupChildren.forEach(child => {
                    const childClass = [...child.classList]

                    if (childClass.includes('slide-left')) {
                        tl.fromTo(child, {xPercent: 15, opacity: 0}, {xPercent: 0, opacity: 1})
                    } else if (childClass.includes('slide-right')) {
                        tl.fromTo(child, {xPercent: -15, opacity: 0}, {xPercent: 0, opacity: 1})
                    } else if (childClass.includes('slide-up')) {
                        tl.fromTo(child, {yPercent: 15, opacity: 0}, {yPercent: 0, opacity: 1})
                    } else if (childClass.includes('slide-down')) {
                        tl.fromTo(child, {yPercent: -15, opacity: 0}, {yPercent: 0, opacity: 1})
                    } else if (childClass.includes('zoom-in')) {
                        tl.fromTo(child, {scale: .8, opacity: 0}, {scale: 1, opacity: 1})
                    } else if (childClass.includes('zoom-out')) {
                        tl.fromTo(child, {scale: 1.2, opacity: 0}, {scale: 1, opacity: 1})
                    } else {
                        tl.fromTo(child, {opacity: 0}, {opacity: 1})
                    }
                })
            })
        }
    }

    group() {
        const group = document.querySelectorAll('.elmntl-group')
        let groupPin;

        if (group.length > 0) {
            group.forEach(elem => {

                groupPin = elem.querySelector('.bg-text')

                ScrollTrigger.create({
                    trigger: elem,
                    toggleClass: 'show',
                    start: "top top",
                    end: "bottom top",
                    pin: groupPin ? groupPin : false,
                    once: groupPin ? false : true,
                })
            })
        }
    }

    video() {
        const videos = document.querySelectorAll('.elmntl-video')

        if (videos.length > 0) {
            videos.forEach(elem => {
                gsap.timeline({
                    scrollTrigger: {
                        trigger: elem,
                        start: "top center",
                        end: "bottom center",
                        onEnter: () => {
                            elem.querySelector('video').play()
                        },
                        onEnterBack: () => {
                            elem.querySelector('video').play()
                        },
                        onLeave: () => {
                            elem.querySelector('video').pause()
                        },
                        onLeaveBack: () => {
                            elem.querySelector('video').pause()
                        }
                    }
                })
            })
        }
    }
}

export default Animations