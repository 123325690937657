/**
 * Manage global libraries like jQuery or THREE from the package.json file
 */

// Import Packages
import 'svgxuse'
import inlineSVG from 'inline-svg'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

// Import modules
import Video from './modules/video'
import Sticky from './modules/sticky'
import Navigation from './modules/navigation'
import ImageBanner from './modules/ImageBanner'
import Animations from './modules/animations'

gsap.registerPlugin(ScrollTrigger)

const App = {
    controller: null,
    initSticky() {
        new Sticky({
            elem: '.site-header',
        })
    },
    initSecondNav() {
        new Navigation({
            elem: '#secondary-navigation',
            toggleBtn: '#secondary-button',
            navMenu: '#secondary-navigation-menu'
        })
    },
    initMobileNav() {
        new Navigation({
            elem: '#mobile-navigation',
            toggleBtn: '#mobile-button-toggle',
            navMenu: '#mobile-navigation-menu'
        })
    },
    initLazyLoadImageBanner() {
        new ImageBanner()
    },
    initVideoBanner() {
        new Video({
            elem: '.banner-video',
            playPause: '#play-pause',
            sound: '#sound'
        })
    },
    initSliderBanner() {
        const bannerSlider = document.querySelector('.banner-slider .main-slider')
        
        if ( bannerSlider !== null ) {
            tns({
                container: bannerSlider,
                mouseDrag: true,
                items: 1,
                loop: false,
                controls: false,
                navPosition: "bottom",
                responsive: { 
                    767: {
                        disable: true
                    }
                }
            })
        }
    },
    initInlineSVG() {
        inlineSVG.init({
            svgSelector: 'img.inline-svg, .a2a_kit img'
        })
    },
    initAnimations() {
        new Animations()
    },
    initRecipeSlider() {
        const recipeSlider = document.querySelectorAll('.recipes-grid .main-slider')
        
        if ( recipeSlider.length > 0 ) {
            recipeSlider.forEach(slider => {
                tns({
                    container: slider,
                    mouseDrag: true,
                    items: 1,
                    loop: false,
                    controls: false,
                    navPosition: "bottom",
                })
            })
        }
    },
    initAgegateShown() {

        window.addEventListener('agegateshown', function (e) {

            const background = document.querySelector('.agegate-background picture')
            const bacgkroundImage = background.querySelector('img')
            
            const backgroundBlend = document.querySelector('.agegate-background .blend-background')

            const backgroundTimeline = gsap.timeline({
                defaults: {
                    duration: .5
                }
            })

            backgroundTimeline.from(background, {opacity: 0})

            inlineSVG.init({
                svgSelector: '.agegate-social-media img.inline-svg'
            })

            bacgkroundImage.onload = () => {
                backgroundTimeline.to(background, {opacity: 1})
                backgroundBlend.classList.add('show')
            }
        })
    },
    init() {

        this.initLazyLoadImageBanner()
        this.initSticky()
        this.initMobileNav()
        this.initVideoBanner()
        this.initSliderBanner()
        this.initInlineSVG()
        this.initAnimations()
        this.initRecipeSlider()
    }
}

window.addEventListener('DOMContentLoaded', () => {
    App.initAgegateShown()
})

window.addEventListener('load', () => App.init())