import ScrollTrigger from 'gsap/ScrollTrigger'

class ImageBanner {

    constructor(props) {
        this.props = props

        this.config = {
            rootMargin: '50px 0px',
            threshold: 0.01
        }

        this.init()
    }

    init() {
        const bannerImage = document.querySelector('.banner-image .banner')

        if (!bannerImage) return

        const images = bannerImage.querySelectorAll('source, img')
        const img = [...images].find(elem => elem.tagName === 'IMG')

        ScrollTrigger.create({
            trigger: bannerImage,
            start: "top bottom",
            once: true,
            onEnter: () => {
                images.forEach(image => {
                    let src 
                    
                    if ( image.tagName.toLowerCase() === 'source' ) {
                        src  = image.dataset.srcset
                        image.srcset = src
                    } else {
                        src = image.dataset.src
                        image.src = src
                    }

                    image.onload = () => {
                        img.classList.add('loaded')
                    }
                })
            }
        })

    }

}

export default ImageBanner